import {post,get} from '../util/request.js';
import {showErrorTip,showSuccessTip} from '../util/index.js'
// 获取独立原创作品列表
const getDetail = async (data) => {
  const res = await get({
    url:'/api/ScratchProjectAPI',
    data
  });
  if(res.error){
    if(res.error !== 'login-required'){
      showErrorTip(res.data);
    }
  }
  return res;
}
// 发布
const share = async (data) => {
  const res = await post({
    url:'/api/ShareScratchProjectAPI',
    data
  })
  if(res.error){
    showErrorTip(res.data);
  }else{
    showSuccessTip('提交成功')
  }
  return res;
};
export {
  share,
  getDetail
}