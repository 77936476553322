import {Input,Form,FormItem,Radio,RadioGroup,Button,Select,Option} from 'element-ui';
import {share,getDetail} from '../api/publish';
import {getConfig} from '../api/index.js';
const iframeurl = '../scratch3/player.html';
export default {
  name:'home',
  components:{
    elInput:Input,
    elForm:Form,
    elFormItem:FormItem,
    elRadio:Radio,
    elRadioGroup:RadioGroup,
    elButton:Button,
    elSelect:Select,
    elOption:Option
  },
  data(){
    this.productId = '';
    return{
      disabled:false,
      tags:[],
      iframeurl,
      headimg:'',
      filename:'',
      ruleForm:{
        desc:'',
        remark:"",
        isCodeOpen:'',
        tag:[]
      },
      rules:{
        isCodeOpen:[
          { required: true, message: '请选择是否公布源代码', trigger: 'change' }
        ],
        desc:[
          { required: true, message: '请填写操作说明', trigger: ['change','blur'] }
        ],
        tag:[
          { required: true, message: '请选择标签', trigger: 'blur' }
        ],
      }
    }
  },
  mounted(){
    this.productId = this.$route.query.id;
    this.iframeurl = `${iframeurl}?id=${this.productId}`
    this.getDetail();
    this.getConfig();
  },
  methods: {
    async getDetail(){
      const res = await getDetail({id:this.productId});
      if(res.error === null){
        const {data} = res;
        const {title='',description='',open_source='',comment,tag} = data;
        this.headimg = data.author.avatar;
        this.filename = title;
        this.ruleForm.desc = description;
        this.ruleForm.remark = comment;
        this.ruleForm.tag = tag;
        this.ruleForm.isCodeOpen = open_source=== true?1:0;
      }
    },
    async getConfig(){
      const res = await getConfig(); 
      const {data = {}} = res;
      const {index_project_tags = []} = data;
      this.tags = index_project_tags.filter(item => item.key !== 'all')
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const {desc,remark,isCodeOpen,tag} = this.ruleForm;
          const data = {
            id:this.productId,
            description:desc,
            comment:remark,
            open_source:isCodeOpen?true:false,
            tag
          };
          const res = await share(data);
          if(res.error === null){
            this.disabled = true;
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}